import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from 'react-emotion';

import { Card, Header, Layout } from 'components';
import config from '../../config/site';

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(${props => props.theme.gridColumns}, 1fr);
  grid-gap: 50px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }

  .gatsby-image-outer-wrapper,
  .gatsby-image-wrapper {
    position: static !important;
  }
`;

const Content = styled.div`
  margin: -6rem auto 6rem auto;
  max-width: ${props => props.theme.maxWidths.general};
  padding: 0 ${props => props.theme.contentPadding} 1.45rem;
  position: relative;
`;

const Name = styled.h2`
  margin: 1rem 0 0.25rem 0;
  color: ${props => props.theme.colors.color};
`;

const Bio = styled.div`
  margin: 1rem 0 0.25rem 0;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${props => props.theme.colors.color};
`;

const Index = ({
  data: {
    allMarkdownRemark: { edges },
  },
}) => (
  <Layout>
    <Header avatar={config.avatar} name={config.name} artist={config.artist} location={config.location} socialMedia={config.socialMedia} />
    <Content>
      <Grid>
        {edges.map(project => (
          <Card
            date={project.node.frontmatter.date}
            title={project.node.frontmatter.title}
            cover={project.node.frontmatter.cover.childImageSharp.fluid}
            path={project.node.fields.slug}
            areas={project.node.frontmatter.areas}
            titles={project.node.frontmatter.titles}
            slug={project.node.fields.slug}
            key={project.node.fields.slug}
          />
        ))}
      </Grid>
    <Bio>
    <div>{config.bio}</div>
    </Bio>
    </Content>
  </Layout>
);

export default Index;

Index.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array.isRequired,
    }),
  }).isRequired,
};

export const pageQuery = graphql`
  query HomeQuery {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            cover {
              childImageSharp {
                fluid(maxWidth: 850, quality: 70, traceSVG: { color: "#328bff" }) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
            date(formatString: "DD.MM.YYYY")
            title
            areas
            titles
          }
        }
      }
    }
  }
`;
