module.exports = {
  pathPrefix: '/', // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "portfolio"

  siteTitle: 'Night by the Fire', // Navigation and Site Title
  siteTitleAlt: 'Night by the Fire: Felting, Weaving, and Other Fiber Arts', // Alternative Site title for SEO
  siteUrl: 'https://www.nightbythefiretextileart.com', // Domain of your site. No trailing slash!
  siteLanguage: 'en', // Language Tag on <html> element
  siteLogo: '/logos/bowl-logo-large.png', // Used for SEO and manifest
  siteDescription: 'Night by the Fire: Fiber Art by Beth Sanders',

  siteFBAppID: '', // Facebook App ID
  userTwitter: '', // Twitter Username
  ogSiteName: '', // Facebook Site Name
  ogLanguage: 'en_US', // og:language
  googleAnalyticsID: '',

  // Manifest and Progress color
  themeColor: '#3498DB',
  backgroundColor: '#2b2e3c',

  // Settings for typography.js
  headerFontFamily: 'Fira Sans',
  bodyFontFamily: 'Montserrat',
  baseFontSize: '16px',

  // Your information
  avatar: '/logos/bowl-icon.png',
  name: 'Night by the Fire',
  artist: 'Beth Sanders',
  bio: 'Textile art has broadened my appreciation of the richness and beauty that surrounds me, and in turn the natural beauty of creation has inspired my investigations with textiles. \nI enjoy working with various textures and colors , attempting to meld them into a gestalt that has a beauty greater than the individual parts . Spurgeon said, “I have learned to kiss the waves that dash me against the Rock of Ages.” My art reflects what I’m learning as I attempt to ride the waves of life.',
  location: 'Salida, CO',
  socialMedia: [
    {
      url: 'https://www.instagram.com/nightbythefiretextileart/',
      name: 'Instagram',
    },
  ],
};
